import classNames from 'classnames';
import { parse } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';

import { formatDateTimeWithLocale, t } from '@/utility/localization';

import LeftRightNavArrowsBtns from '@/components/common/LeftRightNavArrowsBtns';

const ScrollableAvailableBookingDays = ({
  sessionSlots,
  selectedDate,
  handleDateSelection,
  trackLeftNavArrowClickOnBookingDays,
  trackRightNavArrowClickOnBookingDays
}) => {
  const containerRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);

  const showNavArrows = sessionSlots?.days?.length > 2;

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const isEnd =
        container.scrollWidth -
          container.clientWidth -
          container.scrollLeft <=
        1;
      const isStart = container.scrollLeft === 0;

      setIsLeftDisabled(isStart);
      setIsRightDisabled(isEnd);
      setScrollLeft(container.scrollLeft);
    }
  };

  const scrollLeftHandler = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        left: scrollLeft - container.clientWidth,
        behavior: 'smooth'
      });
    }
    trackLeftNavArrowClickOnBookingDays();
  };

  const scrollRightHandler = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        left: scrollLeft + container.clientWidth,
        behavior: 'smooth'
      });
    }
    trackRightNavArrowClickOnBookingDays();
  };

  useEffect(() => {
    handleScroll();
  }, [scrollLeft]);

  return (
    <div className="c-1on1Session-ScrollableAvailableBookingDays">
      <div className="flex justify-between">
        <h3 className="text-heading-xs font-semibold text-dark-1b">
          {t('next-available-dates')}
        </h3>

        {showNavArrows && (
          <LeftRightNavArrowsBtns
            // left btn
            isLeftDisabled={isLeftDisabled}
            onLeftClick={scrollLeftHandler}
            // right btn
            isRightDisabled={isRightDisabled}
            onRightClick={scrollRightHandler}
          />
        )}
      </div>

      <div className="relative mt-16 flex overflow-hidden">
        <div
          ref={containerRef}
          className="flex snap-x snap-mandatory flex-nowrap space-x-8 overflow-x-auto"
          onScroll={handleScroll}>
          {sessionSlots?.days?.map((slot, key) => {
            const parsedDate = parse(slot?.date, 'dd/MM/yyyy', new Date());
            const formattedDate = formatDateTimeWithLocale(
              parsedDate,
              'dd MMM'
            );

            const dayOfWeek = formatDateTimeWithLocale(parsedDate, 'eee');

            const isSelected = selectedDate === slot?.date;
            return (
              <div
                key={key}
                role="button"
                tabIndex={0}
                onClick={() => handleDateSelection(slot, key)}
                className={classNames(
                  'flex-shrink-0 snap-start flex-col items-center justify-center space-y-4 rounded-8 border-1 px-24 py-8',
                  {
                    'border-npl-yellow-light-solid-9 bg-npl-yellow-light-solid-3':
                      isSelected,
                    'border-npl-neutral-light-solid-6 bg-white-default':
                      !isSelected
                  }
                )}>
                <p className="text-center text-label-sm text-dark-1b text-opacity-65">
                  {dayOfWeek}
                </p>
                <p className="whitespace-nowrap text-center text-label-lg font-medium text-black">
                  {formattedDate}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ScrollableAvailableBookingDays;

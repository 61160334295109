import { addDays, format } from 'date-fns';
import { useEffect, useState } from 'react';

import { get1on1SessionSlotsAPI } from '@/services/community/products/session1on1Service';

import { areAnySpotsAvailable } from '../utils';

const use1on1SessionSlots = ({
  communityId,
  oneOnOneSessionId,
  timezoneId
}) => {
  const [sessionSlots, setSessionSlots] = useState([]);
  const [areSlotsAvailable, setAreSlotsAvailable] = useState(false);
  const [isFetchingSlots, setIsFetchingSlots] = useState(true); // Add this line

  useEffect(() => {
    const get1on1SessionSlots = async () => {
      try {
        setIsFetchingSlots(true); // Set isFetchingSlots to true before making the API call

        // Get the current date
        const currentDate = new Date();

        // Calculate the end date (30 days later)
        const endDate = addDays(currentDate, 30);

        // Format dates as "DD/MM/YYYY"// Format dates as "DD/MM/YYYY"
        const formattedStartDate = format(currentDate, 'dd/MM/yyyy');
        const formattedEndDate = format(endDate, 'dd/MM/yyyy');

        // Call the API to retrieve the session slots
        const { data, error } = await get1on1SessionSlotsAPI({
          sessionId: oneOnOneSessionId,
          communityId: communityId,
          rangeStart: formattedStartDate,
          rangeEnd: formattedEndDate,
          timezone: timezoneId
        });

        if (error) {
          console.error('Error retrieving session slots:', error);
          return;
        }

        const slots = data?.data;

        // Update the state variable with the retrieved slots
        setSessionSlots(slots);
        setAreSlotsAvailable(areAnySpotsAvailable(slots)); // Update areSlotsAvailable based on the retrieved slots
        setIsFetchingSlots(false); // Set isFetchingSlots to false after the API call is completed
      } catch (error) {
        console.error('Error retrieving session slots:', error);
        setIsFetchingSlots(false); // Set isFetchingSlots to false if there is an error
      }
    };

    get1on1SessionSlots();
  }, [communityId, oneOnOneSessionId, timezoneId]);

  return {
    sessionSlots,
    isFetchingSlots, // Add isFetchingSlots to the returned object
    areSlotsAvailable
  };
};

export default use1on1SessionSlots;

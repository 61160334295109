import {
  getGMTOffset,
  getHourAndMinuteByLocale
} from '@/utility/dateHelper';
import { formatDateTimeWithLocale, t } from '@/utility/localization';

import AddToCalendarButton from '@/components/member/AddToCalendarButton';
import NPLButton from '@/components/npl/NPLButton';

import useLocale from '@/hooks/useLocale';

import { getBookingDetails } from '../../utils';

const MobileUpcomingBookingCard = ({
  upcomingBookingData,
  onJoinClick
}) => {
  const { currentLocale } = useLocale();

  const gmt = getGMTOffset();

  const { calendarDetails, isOnline, isOneHourBefore } = getBookingDetails(
    upcomingBookingData
  );

  const dayMonth = formatDateTimeWithLocale(
    new Date(upcomingBookingData.sessionStartTime),
    'dd MMM'
  );
  const hourAndMinuteTime = getHourAndMinuteByLocale({
    date: new Date(upcomingBookingData.sessionStartTime),
    locale: currentLocale
  });
  return (
    <div className="fixed bottom-0 z-20 flex w-full justify-between gap-x-16 border-t border-npl-neutral-light-solid-4 bg-npl-base-white px-12 py-16 shadow-npl-styles-shadow-03">
      <div>
        <h3 className="font-medium text-npl-text-icon-on-light-surface-primary">
          {t('upcoming-one-to-one-session')}
        </h3>
        <div className="text-npl-text-icon-on-light-surface-secondary">
          {dayMonth}, {hourAndMinuteTime} {gmt}
        </div>
      </div>
      <div className="flex items-center justify-center gap-x-8">
        {!isOnline ? (
          <div className="flex h-40 w-40 items-center justify-center rounded-full border border-npl-neutral-light-solid-7 hover:bg-npl-neutral-light-solid-7">
            <AddToCalendarButton
              calendarData={{
                event: {
                  startTime: calendarDetails.startTime,
                  endTime: calendarDetails.endTime,
                  name: calendarDetails.title,
                  location: calendarDetails.location
                }
              }}
              stretch={true}
              buttonSize="md"
              icon={true}
            />
          </div>
        ) : (
          <>
            {!isOneHourBefore() && (
              <div className="flex h-40 w-40 items-center justify-center rounded-full border border-npl-neutral-light-solid-7 hover:bg-npl-neutral-light-solid-7">
                <AddToCalendarButton
                  calendarData={{
                    event: {
                      startTime: calendarDetails.startTime,
                      endTime: calendarDetails.endTime,
                      name: calendarDetails.title,
                      location: calendarDetails.location
                    }
                  }}
                  stretch={true}
                  buttonSize="md"
                  icon={true}
                />
              </div>
            )}
            <NPLButton
              hierarchy="accent_primary"
              buttonText={t('join')}
              disabled={!isOneHourBefore()}
              onClick={onJoinClick}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MobileUpcomingBookingCard;

import { format } from 'date-fns';
import React, { useEffect } from 'react';

import { t } from '@/utility/localization';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import { showConfetti } from '@/components/common/ConfettiContainer';
import SuccessCheckIcon from '@/components/common/Icons/SuccessCheckIcon';
import Modal from '@/components/common/Modal';
import MobileDrawer from '@/components/member/MobileDrawer';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import { getSessionBookingStartAndEndTimeRangeWithoutDate } from '../../utils';

const SessionBookingSuccessModal = ({ open, onClose, bookingData }) => {
  const { isGtEqMd } = useWindowWidthContext();

  const {
    sessionStartTime,
    sessionObjectId: sessionDetails,
    attendeeLearnerObjectId: attendeeDetails
  } = bookingData;
  const { location } = sessionDetails;

  const email = attendeeDetails?.email;

  const items = [
    {
      icon: 'calendar',
      label: format(new Date(sessionStartTime), 'iii, d MMM') // Sat, 1 Jan
    },
    {
      icon: 'clock',
      label: getSessionBookingStartAndEndTimeRangeWithoutDate(bookingData)
    },
    {
      icon:
        location?.type === 'inPerson' ? 'marker-pin-01' : 'video-recorder',
      label:
        location?.type === 'inPerson'
          ? location.locationValue
          : t('online')
    }
  ];

  const renderInnerContent = () => {
    const title = t('your-1-1-session-is-booked');
    const desc = t('a-confirmation-email-has-been-sent-to-email', {
      email
    });

    return (
      <div className="px-24 py-32 md:px-40 md:py-40">
        <div>
          <div className="mb-12 md:flex md:justify-center">
            <SuccessCheckIcon width="80" height="80" />
          </div>
          <h2 className="text-heading-md font-semibold text-dark-1b md:text-center">
            {title}
          </h2>
          <p className="mt-8 text-body-md text-dark-1b md:text-center">
            {desc}
          </p>
        </div>

        {/* Time & Location */}
        <div className="mt-32 flex-col space-y-16 rounded-12 bg-npl-neutral-light-solid-2 p-12 md:mt-24">
          {items.map(({ icon, label }, key) => {
            return (
              <div key={key} className="flex items-center space-x-12">
                <div className="shrink-0">
                  <Icon
                    name={icon}
                    width={20}
                    height={20}
                    fill="#1B1B18A6"
                  />
                </div>
                <p className="text-label-lg text-dark-1b">{label}</p>
              </div>
            );
          })}
        </div>

        {/* Buttons */}
        <div className="mt-32 md:mt-40">
          <NPLButton
            hierarchy="accent_primary"
            onClick={onClose}
            stretch
            buttonText={t('continue')}
          />
        </div>
      </div>
    );
  };

  // Show celebration confetti on modal open
  useEffect(() => {
    showConfetti();
  }, []);

  return isGtEqMd ? (
    <Modal
      open={open}
      size="md"
      //   customContainerClass="!p-0"
      customInnerClass="!p-0"
      customContainerStyle={{
        width: '480px'
      }}
      showCloseIcon={false}
      onClose={onClose}>
      {renderInnerContent()}
    </Modal>
  ) : (
    <MobileDrawer
      open={open}
      customHeight="h-auto"
      showCloseIcon={false}
      withoutPadding={true}
      onClose={onClose}>
      {renderInnerContent()}
    </MobileDrawer>
  );
};

export default SessionBookingSuccessModal;

import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import CloseIcon from '@/components/common/Icons/CloseIcon';

import useOutsideComponentClickDetect from '@/hooks/useOutsideComponentClickDetect';

const slideAnimationConfig = {
  duration: 200,
  fill: 'forwards'
};

const slideInKeyframes = [
  { transform: 'translateY(0)' },
  { transform: 'translateY(100%)' }
];

const slideOutKeyframes = [
  { transform: 'translateY(100%)' },
  { transform: 'translateY(0)' }
];

const MobileDrawer = ({
  onClose,
  open,
  children,
  customHeight,
  showCloseIcon = true,
  withoutPadding = false,
  seeThroughContainer,
  placeInnerContentBottom
}) => {
  const drawerRef = useRef();

  function onOutsideClick(e) {
    // If user clicked inside a modal, ignore the click
    if (e.target.closest('#modal')) {
      return;
    }

    onClose(e);
  }

  // useOutsideComponentClickDetect(drawerRef, onClose);
  useOutsideComponentClickDetect(drawerRef, onOutsideClick);

  useEffect(() => {
    if (drawerRef.current) {
      if (open) {
        drawerRef.current.animate(slideOutKeyframes, slideAnimationConfig);
      } else {
        drawerRef.current.animate(slideInKeyframes, slideAnimationConfig);
      }
    }
  }, [open]);

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (document.body) {
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = originalStyle;
      }
    }

    return () => {
      if (document.body) return (document.body.style.overflow = 'visible');
    };
  }, [open]);

  return createPortal(
    <div
      className={classNames(
        'h-full-dvh fixed left-0 top-0 z-over-intercom flex w-screen flex-col justify-end',
        { flex: open, hidden: !open },
        seeThroughContainer
          ? 'bg-black/80 backdrop-blur-lg'
          : 'bg-npl-transparent-black-50 backdrop-blur-sm'
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClose(e);
      }}>
      <div
        className={classNames(
          'mt-auto w-full overflow-auto rounded-t-28 shadow-npl-styles-shadow-01',
          customHeight ? customHeight : 'h-[calc(100%-48px)]',
          withoutPadding ? '' : 'px-16 py-12',
          seeThroughContainer
            ? '!rounded-12 !bg-npl-transparent-white-25 !p-0'
            : 'bg-white-default',
          { 'flex flex-col justify-end': placeInnerContentBottom }
        )}
        ref={drawerRef}>
        {showCloseIcon && (
          <div className="flex justify-end p-8">
            <div onClick={onClose} className="cursor-pointer">
              <CloseIcon width={16} height={16} />
            </div>
          </div>
        )}
        <div className="w-full">{children}</div>
      </div>
    </div>,
    document.body
  );
};

export default MobileDrawer;

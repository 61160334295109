import { atcb_action } from 'add-to-calendar-button/unstyle';
import { format } from 'date-fns';
import React from 'react';

import { getTimezoneId } from '@/utility/dateHelper';

import HeadlessDropdown from '@/components/common/Form/HeadlessDropdown/HeadlessDropdown';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import { supportedCalendars } from './constants';

const AddToCalendarButton = ({
  calendarData,
  buttonSize = 'sm',
  stretch = false,
  icon = false
}) => {
  if (!calendarData) {
    return null;
  }

  const startTime = new Date(calendarData.event.startTime);
  const endTime = new Date(calendarData.event.endTime);

  const calendarDateData = {
    startDate: format(startTime, 'yyyy-MM-dd'),
    startTime: format(startTime, 'HH:mm'),
    endDate: format(endTime, 'yyyy-MM-dd'),
    endTime: format(endTime, 'HH:mm')
  };

  const userTimezoneId = getTimezoneId();

  const buttonConfig = {
    name: calendarData.event.title,
    description: calendarData.event?.description,
    location: calendarData.event.location,
    startDate: calendarDateData.startDate,
    startTime: calendarDateData.startTime,
    endDate: calendarDateData.endDate,
    endTime: calendarDateData.endTime,
    timeZone: userTimezoneId
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <HeadlessDropdown
        stretch={stretch}
        renderField={() =>
          icon ? (
            <Icon
              name="calendar-plus-01"
              width={20}
              height={20}
              className="fill-npl-text-icon-on-light-surface-secondary"
            />
          ) : (
            <NPLButton
              hierarchy="outline"
              buttonText={calendarData.buttonLabel}
              size={buttonSize}
              stretch={stretch}
            />
          )
        }
        renderOptions={({ closeDropdown }) => {
          return (
            <div className="rounded-12 bg-white-default p-8">
              {supportedCalendars.map((option, index) => {
                return (
                  <div key={index}>
                    <div className="rounded-12 bg-white-default">
                      <button
                        className="flex w-full items-center space-x-12 rounded-8 px-8 py-10 text-label-md text-dark-1b hover:bg-npl-neutral-light-solid-3"
                        onClick={() => {
                          atcb_action({
                            ...buttonConfig,
                            options: [option.value]
                          });
                          closeDropdown();
                        }}>
                        <div className="text-label-md">{option.label}</div>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }}></HeadlessDropdown>
    </div>
  );
};

export default AddToCalendarButton;

import React from 'react';

import { t } from '@/utility/localization';

import OneOnOneSessionMetaTag from '../OneOnOneSessionMetaTag';

const Overview = ({ resourceInfo, discountedOneOnOneSessionPrice }) => {
  const { title, durationIntervalInMinutes, hostInfo } = resourceInfo;
  const { hostName } = hostInfo || {};
  return (
    <div className="c-OneOnOneSession-Overview p-24 lg:px-32">
      {/* Duration with Host */}
      <h3 className="text-heading-sm font-semibold text-npl-yellow-light-solid-11">
        {t('durationmins-min-with-hostname', {
          durationMins: durationIntervalInMinutes,
          hostName
        })}
      </h3>
      {/* Title */}
      <h4 className="mt-4 text-heading-xl font-semibold text-dark-1b">
        {title}
      </h4>
      {/* Price and Location */}
      <div className="mt-16">
        <OneOnOneSessionMetaTag
          oneOnOneSessionData={resourceInfo}
          discountedOneOnOneSessionPrice={discountedOneOnOneSessionPrice}
          isManagement={false}
        />
      </div>
    </div>
  );
};

export default Overview;

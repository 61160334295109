import React from 'react';

import { t } from '@/utility/localization';

import LinkAwareText from '@/components/common/LinkAwareText';
import { ReadMore } from '@/components/common/ReadMore/ReadMore';

const AboutOneOnOneSession = ({ communityDescription }) => {
  return (
    <div className="c-OneOnOneSession-AboutOneOnOneSession border-t-1 border-[#0000001A] py-40">
      {/* Title */}
      <h3 className="text-heading-sm font-semibold text-dark-1b">
        {t('about-this-session')}
      </h3>
      {/* Description */}
      <ReadMore
        withShowLess
        maxHeight={144}
        buttonStyling="text-label-lg font-semibold text-npl-yellow-light-solid-11"
        className="mt-16 whitespace-pre-line break-words text-body-md font-normal text-npl-text-icon-on-light-surface-primary">
        <LinkAwareText text={communityDescription} />
      </ReadMore>
    </div>
  );
};

export default AboutOneOnOneSession;

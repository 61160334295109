import classNames from 'classnames';
import React, { useState } from 'react';

import { t } from '@/utility/localization';

import TimezoneHeadlessSelect from '@/components/common/TimezoneHeadlessSelect/TimezoneHeadlessSelect';
import NPLButton from '@/components/npl/NPLButton';

import { addMinutesToDate, formatSlotTime } from '../../utils';
import ScrollableAvailableBookingDays from './ScrollableAvailableBookingDays';

const BookAvailableSlotsCard = ({
  sessionSlots,
  durationInMins,
  selectedTimeZoneId,
  setSelectedTimeZoneId,
  onBookSlotClick,
  isBookingSlot,
  // analytics
  trackLeftNavArrowClickOnBookingDays,
  trackRightNavArrowClickOnBookingDays,
  trackChangeBookingDate,
  trackChangeBookingTime
}) => {
  const [selectedDate, setSelectedDate] = useState(
    sessionSlots?.days[0]?.date || null
  );
  const [selectedSpot, setSelectedSpot] = useState(
    sessionSlots?.days[0]?.spots[0] || null
  );

  const handleDateSelection = (slot, position) => {
    setSelectedDate(slot?.date);
    setSelectedSpot(slot?.spots[0]);
    // analytics
    trackChangeBookingDate({
      slotData: slot,
      position
    });
  };

  const handleSpotSelection = (spot) => {
    setSelectedSpot(spot);

    // analytics
    trackChangeBookingTime({
      slotSpot: spot
    });
  };

  const handleBookSlotClick = () => {
    onBookSlotClick({
      sessionStartTime: selectedSpot?.start_time,
      sessionEndTime: addMinutesToDate(
        selectedSpot?.start_time,
        durationInMins
      )
    });
  };

  const selectedSlot = sessionSlots?.days?.find(
    (slot) => slot?.date === selectedDate
  );

  return (
    <div className="c-OneOnOneSession-BookAvailableSlotsCard flex flex-col space-y-40 rounded-28 bg-white-default p-32 shadow-npl-styles-shadow-01">
      {/* Next available dates */}
      <ScrollableAvailableBookingDays
        sessionSlots={sessionSlots}
        selectedDate={selectedDate}
        handleDateSelection={handleDateSelection}
        //analytics
        trackLeftNavArrowClickOnBookingDays={
          trackLeftNavArrowClickOnBookingDays
        }
        trackRightNavArrowClickOnBookingDays={
          trackRightNavArrowClickOnBookingDays
        }
      />

      {/* Pick a time */}
      <div>
        <h3 className="text-heading-xs font-semibold text-dark-1b">
          {t('pick-a-time')}
        </h3>
        <div className="mt-16 flex flex-wrap gap-8">
          {selectedSlot?.spots?.map((spot, key) => {
            const isSelected =
              selectedSpot?.start_time &&
              selectedSpot?.start_time === spot?.start_time;

            return (
              <div
                key={key}
                role="button"
                tabIndex={0}
                onClick={() => handleSpotSelection(spot)}
                className={classNames(
                  'flex w-[144px] items-center justify-center rounded-8 border-1 px-20 py-12',
                  {
                    'border-npl-yellow-light-solid-9 bg-npl-yellow-light-solid-3':
                      isSelected,
                    'border-npl-neutral-light-solid-6 bg-white-default':
                      !isSelected
                  }
                )}>
                <p className="text-center text-label-lg font-medium text-dark-1b">
                  {formatSlotTime(spot?.start_time)}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Pick a time zone */}
      <TimezoneHeadlessSelect
        value={selectedTimeZoneId}
        onChange={setSelectedTimeZoneId}
      />

      {/* Book CTA */}
      <NPLButton
        hierarchy="accent_primary"
        buttonText={t('book')}
        stretch
        disabled={!selectedDate || !selectedSpot}
        onClick={handleBookSlotClick}
        loading={isBookingSlot}
      />
    </div>
  );
};

export default BookAvailableSlotsCard;

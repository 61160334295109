import React, { useEffect, useState } from 'react';

import { addMinutesToDate } from '@/features/1on1Sessions/utils';

import SelectDatePopup from './SelectDatePopup';
import SelectSpotPopup from './SelectSpotPopup';

const POPUP_VIEW = {
  DATE_SELECTION: 'DATE_SELECTION',
  SPOT_SELECTION: 'SPOT_SELECTION'
};
const SelectBookingSlotPopup = ({
  open,
  sessionSlots,
  durationInMins,
  selectedTimeZoneId,
  setSelectedTimeZoneId,
  onBookSlotClick,
  onClose
}) => {
  const [activePopup, setActivePopup] = useState(null);

  const [selectedDate, setSelectedDate] = useState(
    sessionSlots?.days?.[0]?.date || null
  );
  const [selectedSpot, setSelectedSpot] = useState(
    sessionSlots?.days?.[0]?.spots?.[0] || null
  );

  const selectedSlot = sessionSlots?.days?.find(
    (slot) => slot?.date === selectedDate
  );

  const handleDateSelection = (slot) => {
    setSelectedDate(slot?.date);
    setSelectedSpot(slot?.spots[0]);
    setActivePopup(POPUP_VIEW.SPOT_SELECTION);
  };

  const handleSpotSelection = (spot) => {
    setSelectedSpot(spot);
  };

  const goToDateSelectionView = () =>
    setActivePopup(POPUP_VIEW.DATE_SELECTION);

  const handleBookSlotClick = () => {
    onBookSlotClick({
      sessionStartTime: selectedSpot?.start_time,
      sessionEndTime: addMinutesToDate(
        selectedSpot?.start_time,
        durationInMins
      )
    });
  };

  const handleClose = () => {
    setActivePopup(null);
    onClose();
  };

  // switch (activePopup) {
  //   case POPUP_VIEW.DATE_SELECTION:
  //     return (
  //       <SelectDatePopup
  //         selectedDate={selectedDate}
  //         sessionSlots={sessionSlots}
  //         onDateSelection={handleDateSelection}
  //         onClose={onClose}
  //       />
  //     );

  //   case POPUP_VIEW.SPOT_SELECTION:
  //     return (
  //       <SelectSpotPopup
  //         selectedSpot={selectedSpot}
  //         onSpotSelection={handleSpotSelection}
  //         selectedTimeZoneId={selectedTimeZoneId}
  //         setSelectedTimeZoneId={setSelectedTimeZoneId}
  //         onBack={goToDateSelectionView}
  //         onBookSlotClick={handleBookSlotClick}
  //         onClose={onClose}
  //       />
  //     );
  //   default:
  //     return null;
  // }

  useEffect(() => {
    if (open) {
      setActivePopup(POPUP_VIEW.DATE_SELECTION);
      setSelectedDate(sessionSlots?.days?.[0]?.date);
      setSelectedSpot(sessionSlots?.days?.[0]?.spots?.[0]);
    }
  }, [open, sessionSlots]);

  return (
    <div>
      <SelectDatePopup
        open={activePopup === POPUP_VIEW.DATE_SELECTION}
        selectedDate={selectedDate}
        sessionSlots={sessionSlots}
        onDateSelection={handleDateSelection}
        onClose={handleClose}
      />
      <SelectSpotPopup
        open={activePopup === POPUP_VIEW.SPOT_SELECTION}
        selectedSlot={selectedSlot}
        selectedSpot={selectedSpot}
        onSpotSelection={handleSpotSelection}
        selectedTimeZoneId={selectedTimeZoneId}
        setSelectedTimeZoneId={setSelectedTimeZoneId}
        onBack={goToDateSelectionView}
        onBookSlotClick={handleBookSlotClick}
        onClose={handleClose}
      />
    </div>
  );
};

export default SelectBookingSlotPopup;

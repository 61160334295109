import { isSameDay } from 'date-fns';
import React, { useMemo } from 'react';

import { getGMTOffset } from '@/utility/dateHelper';
import { getEventTypeLabel } from '@/utility/eventUtils';
import { formatDateTimeWithLocale, t } from '@/utility/localization';

import useLocale from '@/hooks/useLocale';

import {
  EVENT_ACCESS_PAID,
  EVENT_TYPE_IN_PERSON,
  EVENT_TYPE_LIVE
} from '@/pages/portal/events/constants';
import { getPriceText } from '@/pages/portal/utils';

import Icon from '../npl/Icon';
import CalendarDayBox from './CalendarDayBox';
import ClampText from './ClampText';
import CopyIconWithTooltip from './CopyIconWithTooltip';
import NplToolTip from './NplToolTip';
import PortalEntityPriceDisplayTag from './PortalEntityPriceDisplayTag';

export const getStartAndEndTimeRange = (d1, d2) => {
  const startTime = new Date(d1);
  const endTime = new Date(d2);
  if (isSameDay(startTime, endTime)) {
    const formattedStart = formatDateTimeWithLocale(startTime, 'hh:mm aa');
    const formattedEnd = formatDateTimeWithLocale(endTime, 'hh:mm aa');
    return `${formattedStart} - ${formattedEnd}`;
  } else {
    const formattedStart = formatDateTimeWithLocale(startTime, 'hh:mm aa');
    const endDateAndMonth = formatDateTimeWithLocale(endTime, 'dd MMM');
    const endTiming = formatDateTimeWithLocale(endTime, 'hh:mm aa');
    const formattedEnd = `${endDateAndMonth}, ${endTiming}`;
    return `${formattedStart} - ${formattedEnd}`;
  }
};

const EventDateLocAccessBlock = (props) => {
  const {
    showAdminView,
    eventData,
    discountedEventPrice,
    isUserRegistered,
    linkIsSecondaryColor = false,
    darkCalenderBox = true
  } = props;
  const { currentLocale } = useLocale();
  const {
    attendeeLimit,
    requiresApproval,
    isCapacitySet,
    hideLocation,
    pricingConfig
  } = eventData || {};
  const eventStartTime = new Date(eventData?.startTime);

  const isRegistrationClosed = !!eventData?.isRegistrationClosed;

  const eventCurrency =
    eventData?.priceDetails?.localCurrency ??
    eventData?.priceDetails?.currency ??
    eventData?.currency ??
    'USD';

  const eventPrice =
    eventData?.access === EVENT_ACCESS_PAID
      ? (eventData?.priceDetails?.localAmount ??
        eventData?.priceDetails?.amount ??
        eventData?.amount)
      : 0;

  const eventLocationIcon =
    eventData?.type === EVENT_TYPE_LIVE
      ? 'video-recorder'
      : eventData?.type === EVENT_TYPE_IN_PERSON
        ? 'marker-pin-01'
        : 'video-recorder';

  const renderEventLocationMetadata = () => {
    let eventType = eventData?.type;

    const eventLocation =
      eventType === EVENT_TYPE_LIVE
        ? eventData?.liveLink
        : eventType === EVENT_TYPE_IN_PERSON
          ? eventData?.inPersonLocation
          : '';

    // if not in admin view or user is not registered, then assume that location exits.
    // else, check event location.
    const locationExists =
      !showAdminView || !isUserRegistered || !!eventLocation;

    // TODO: @Aman
    // const isLocaitonALink = BOOL

    // Summary of Logic below :-
    // if (
    //   (admin && locationExists) ||
    //   (isRegistered && locationExists)
    //   )
    //   - show type and location
    // if (admin && !locaitonExists)
    //   - show no location added
    // if (locationExists && !isRegistered)
    //   - show type + register to get link
    // default if (!locationExists)
    //   - show type only

    const getLocationText = () => {
      if (!eventLocation) {
        return;
      }

      return (
        <div className="mt-4 flex space-x-8">
          <ClampText
            text={eventLocation}
            line={1}
            collapsedCtaText=""
            // disableLinkAware
            linkIsSecondaryColor={linkIsSecondaryColor}
            customClassNames="text-label-md !text-dark-1b !text-opacity-65"
            ctaCustomClassNames="!text-npl-text-icon-on-light-surface-secondary/65"
          />
          <CopyIconWithTooltip textToCopy={eventLocation} />
        </div>
      );
    };

    if ((showAdminView || isUserRegistered) && locationExists) {
      return (
        <div className="flex flex-col justify-center ">
          <p className="text-label-md font-medium text-dark-1b">
            {getEventTypeLabel(t, eventData?.type)}
          </p>
          {eventLocation && getLocationText()}
        </div>
      );
    }

    if (showAdminView && !locationExists) {
      return (
        <div className="flex flex-col justify-center">
          <p className="text-label-md text-dark-1b">
            {t('no-location-added')}
          </p>
        </div>
      );
    }

    if (!isUserRegistered && locationExists) {
      return (
        <div className="flex flex-col justify-center">
          <p className="text-label-md font-medium text-dark-1b">
            {getEventTypeLabel(t, eventData?.type)}
          </p>
          <p className="mt-4 text-label-md text-dark-1b text-opacity-65">
            {eventType === EVENT_TYPE_IN_PERSON
              ? hideLocation
                ? t('register-to-get-address')
                : getLocationText()
              : t('register-to-get-link')}
          </p>
        </div>
      );
    }

    return (
      <div className="flex flex-col justify-center">
        <p className="text-label-md font-medium text-dark-1b">
          {getEventTypeLabel(t, eventData?.type)}
        </p>
      </div>
    );
  };

  const eventPricingSubtitles = {
    [t('approval-required')]: requiresApproval,
    [t('max-number', {
      number: attendeeLimit
    })]: isCapacitySet && Boolean(attendeeLimit)
  };

  const priceText = useMemo(() => {
    return getPriceText(
      eventPrice,
      discountedEventPrice,
      eventCurrency,
      eventData?.pricingConfig?.priceType
    );
  }, [
    discountedEventPrice,
    eventCurrency,
    eventData?.pricingConfig?.priceType,
    eventPrice
  ]);

  return (
    <div className="c-EventDateLocAccessBlock flex flex-col space-y-16">
      {/* Event Time */}
      <div className="flex space-x-16">
        {/* Icon */}
        <div className="h-40 w-40 rounded-8 bg-white-default">
          {eventData?.startTime && (
            <CalendarDayBox
              dark={darkCalenderBox}
              date={eventData.startTime}
            />
          )}
        </div>
        <div className="flex flex-col justify-center">
          <p className="text-label-md font-medium text-dark-1b">
            {formatDateTimeWithLocale(eventStartTime, 'eee, dd MMM')}
          </p>
          <div className="mt-4 flex items-center space-x-8 text-label-md text-dark-1b text-opacity-65">
            <p>
              {getStartAndEndTimeRange(
                eventData?.startTime,
                eventData?.endTime,
                currentLocale
              )}
            </p>
            <NplToolTip
              direction="bottom"
              text={
                <span className="w-[178px]">
                  <span>
                    {t('shown-in-local-time-zone-2', {
                      gmtOffSet: getGMTOffset()
                    })}
                  </span>
                </span>
              }>
              <Icon
                name="info-circle"
                width="16"
                height="16"
                fill="#1B1B1880"
              />
            </NplToolTip>
          </div>
        </div>
      </div>

      {/* Event Location */}
      <div className="flex space-x-16">
        {/* Icon */}
        <div className="flex h-40 w-40 shrink-0 items-center justify-center rounded-8 border-1 border-npl-neutral-light-solid-6 bg-white-default">
          <Icon
            width="20"
            height="20"
            name={eventLocationIcon}
            fill="#1B1B1880"
          />
        </div>
        {/* EventLocationMetadata */}
        {renderEventLocationMetadata()}
      </div>

      {/* Event Access */}
      <div className="flex space-x-16">
        {/* Icon */}
        <div className="flex h-40 w-40 items-center justify-center rounded-8 border-1 border-npl-neutral-light-solid-6 bg-white-default">
          <Icon width="20" height="20" name="ticket-01" fill="#1B1B1880" />
        </div>
        <div className="flex flex-col justify-center">
          {isRegistrationClosed ? (
            <p className="text-label-md font-medium text-dark-1b">
              {t('registration-closed')}
            </p>
          ) : (
            <>
              {showAdminView ? (
                <PortalEntityPriceDisplayTag
                  currency={eventCurrency}
                  entityType="event"
                  pricingConfig={{ ...pricingConfig, amount: eventPrice }}
                  discountedPrice={discountedEventPrice}
                />
              ) : (
                <p className="text-label-md font-medium text-dark-1b">
                  {priceText}
                </p>
              )}

              <p className="mt-4 text-label-md text-dark-1b text-opacity-65">
                {Object.entries(eventPricingSubtitles)
                  .filter(([_, value]) => value)
                  .map(([key, _]) => key)
                  .join(' • ')}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventDateLocAccessBlock;

import React, { useMemo } from 'react';

import { t } from '@/utility/localization';

import ClampText from '@/components/common/ClampText';
import CopyIconWithTooltip from '@/components/common/CopyIconWithTooltip';

import { getPriceText } from '@/pages/portal/utils';

import IconBlockWithText from '../../../components/common/IconBlockWithText/IconBlockWithText';
import {
  PRODUCT_ACCESS_PAID,
  SESSION_1_ON_1_ONLINE
} from '../../../pages/portal/products/constants';
import getConstants from '../constants';

function OneOnOneSessionMetaTag({
  oneOnOneSessionData,
  isManagement,
  discountedOneOnOneSessionPrice
}) {
  const { ONE_ON_ONE_SESSION_LOCATION_TYPE_LABELS } = getConstants();

  const isPaidAccess = oneOnOneSessionData?.access === PRODUCT_ACCESS_PAID;
  const currency =
    oneOnOneSessionData?.priceDetails?.localCurrency ??
    oneOnOneSessionData?.priceDetails?.currency;
  const price =
    oneOnOneSessionData?.priceDetails?.localAmount ??
    oneOnOneSessionData?.priceDetails?.amount;
  const locationType = oneOnOneSessionData?.location?.type;
  const isOnline = locationType === SESSION_1_ON_1_ONLINE;
  const location = oneOnOneSessionData?.location?.locationValue;

  // @Trent TODO:  Implement price to render on e server side, for crawlers
  const priceText = useMemo(() => {
    if (!isPaidAccess) return '';

    return getPriceText(
      price,
      discountedOneOnOneSessionPrice,
      currency,
      oneOnOneSessionData?.pricingConfig?.priceType
    );
  }, [discountedOneOnOneSessionPrice, currency, isPaidAccess, price]);

  const showLocationLink =
    (isOnline && isManagement) || oneOnOneSessionData?.showLocation;

  const sessionDetailsProps = [
    {
      icon: isOnline ? 'video-recorder' : 'marker-pin-01',
      title: ONE_ON_ONE_SESSION_LOCATION_TYPE_LABELS[locationType],
      description: !location ? (
        ''
      ) : showLocationLink ? (
        <div className="mt-4 flex space-x-8">
          <ClampText
            text={location}
            line={1}
            collapsedCtaText=""
            // disableLinkAware
            linkIsSecondaryColor={true}
            customClassNames="text-label-md !text-dark-1b !text-opacity-65"
            ctaCustomClassNames="!text-npl-text-icon-on-light-surface-secondary/65"
          />
          <CopyIconWithTooltip textToCopy={location} />
        </div>
      ) : (
        <div className="mt-4 flex space-x-8">
          <ClampText
            text={location}
            line={1}
            collapsedCtaText=""
            disableLinkAware
            linkIsSecondaryColor={true}
            customClassNames="text-label-md !text-dark-1b !text-opacity-65"
            ctaCustomClassNames="!text-npl-text-icon-on-light-surface-secondary/65"
          />
          <CopyIconWithTooltip textToCopy={location} />
        </div>
      )
    },
    {
      icon: 'ticket-01',
      title: isPaidAccess ? priceText : t('free')
    }
  ];

  return <IconBlockWithText items={sessionDetailsProps} />;
}

export default OneOnOneSessionMetaTag;

import React from 'react';

import NPLButton from '../npl/NPLButton';

const LeftRightNavArrowsBtns = ({
  onLeftClick,
  onRightClick,
  isLeftDisabled,
  isRightDisabled
}) => {
  return (
    <div className="c-LeftRightNavArrowsBtns">
      <NPLButton
        hierarchy="plain"
        size="sm"
        leadIcon="chevron-left"
        onClick={onLeftClick}
        disabled={isLeftDisabled}
        rounded
      />
      <NPLButton
        hierarchy="plain"
        size="sm"
        leadIcon="chevron-right"
        onClick={onRightClick}
        disabled={isRightDisabled}
        rounded
      />
    </div>
  );
};

export default LeftRightNavArrowsBtns;

import React from 'react';

import { t } from '@/utility/localization';

import LinkAwareText from '@/components/common/LinkAwareText';
import { ReadMore } from '@/components/common/ReadMore/ReadMore';

import HostCard from './HostCard';

const AboutTheHost = ({ hostInfo }) => {
  const { hostBio } = hostInfo || {};
  // const hostBio =
  //   "As the Co-founder of 100 Days Challenge, I specialize in creating personalized fitness plans. With over 10 years of experience in health and wellness, I'm passionate about helping others achieve their fitness goals through tailored advice and support.";

  return (
    <div className="c-OneOnOneSession-AboutTheHost border-t-1 border-[#0000001A] py-40">
      {/* Title */}
      <h3 className="text-heading-sm font-semibold text-dark-1b">
        {t('about-the-host')}
      </h3>
      {/* Host Card */}
      <div className="mt-16">
        <HostCard hostInfo={hostInfo} />
      </div>
      {/* Host Bio */}
      <ReadMore
        withShowLess
        maxHeight={96}
        buttonStyling="text-label-lg font-semibold text-npl-yellow-light-solid-11"
        className="mt-16 whitespace-pre-line break-words text-body-md font-normal text-npl-text-icon-on-light-surface-primary">
        <LinkAwareText text={hostBio} />
      </ReadMore>
    </div>
  );
};

export default AboutTheHost;

import { getGMTOffset } from '@/utility/dateHelper';
import { formatDateTimeWithLocale, t } from '@/utility/localization';

import { getStartAndEndTimeRange } from '@/components/common/EventDateLocAccessBlock';
import AddToCalendarButton from '@/components/member/AddToCalendarButton';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import useLocale from '@/hooks/useLocale';

import { getBookingDetails } from '../../utils';

const UpcomingBookingCard = ({ upcomingBookingData, onJoinClick }) => {
  const { currentLocale } = useLocale();

  const gmt = getGMTOffset();

  const weekdayDayMonth = formatDateTimeWithLocale(
    new Date(upcomingBookingData.sessionStartTime),
    'eee, dd MMM'
  );

  const startAndEndTimeRange = getStartAndEndTimeRange(
    upcomingBookingData.sessionStartTime,
    upcomingBookingData.sessionEndTime,
    currentLocale
  );

  const locationOrLink =
    upcomingBookingData.sessionObjectId.location.locationValue;

  const { calendarDetails, isOnline, isOneHourBefore } = getBookingDetails(
    upcomingBookingData
  );

  return (
    <div className="rounded-28 bg-npl-base-white p-32 shadow-npl-styles-shadow-01 ">
      <div className="">
        <h3 className="text-heading-xs font-semibold text-dark-1b">
          {t('upcoming-one-to-one-session')}
        </h3>
        {/* metadata */}
        <div className="flex flex-col space-y-16 py-16">
          <div className="flex gap-x-12">
            <Icon
              name="calendar"
              width={20}
              height={20}
              className="fill-npl-text-icon-on-light-surface-secondary"
            />
            <div className="flex items-center justify-center text-label-lg text-npl-text-icon-on-light-surface-primary ">
              {weekdayDayMonth}
            </div>
          </div>
          <div className="flex gap-x-12">
            <Icon
              name="clock"
              width={20}
              height={20}
              className="fill-npl-text-icon-on-light-surface-secondary"
            />

            <div className="flex items-center justify-center text-label-lg text-npl-text-icon-on-light-surface-primary ">
              {startAndEndTimeRange} ({gmt})
            </div>
          </div>
          {/* check if online or in person and render correspondingly */}
          <div className="flex gap-x-12">
            <Icon
              name={isOnline ? 'video-recorder' : 'marker-pin-01'}
              width={20}
              height={20}
              className="fill-npl-text-icon-on-light-surface-secondary"
            />
            <div className="flex items-center justify-center text-label-lg text-npl-text-icon-on-light-surface-primary ">
              {isOnline ? t('online') : locationOrLink}
            </div>
          </div>
        </div>
        <div className="h-[1px] w-full bg-npl-neutral-light-solid-4"></div>
        {/* cta */}
        <div className="mt-24 flex flex-col space-y-12">
          {!isOnline ? (
            <AddToCalendarButton
              calendarData={{
                event: {
                  title: calendarDetails.title,
                  startTime: calendarDetails.startTime,
                  endTime: calendarDetails.endTime,
                  name: calendarDetails.title,
                  location: calendarDetails.location
                },
                buttonLabel: t('add-to-calendar')
              }}
              stretch={true}
              buttonSize="md"
            />
          ) : (
            <>
              <NPLButton
                hierarchy="accent_primary"
                buttonText={t('join')}
                disabled={!isOneHourBefore()}
                stretch
                onClick={onJoinClick}
              />
              {/* add to calendar */}
              {!isOneHourBefore() && (
                <AddToCalendarButton
                  calendarData={{
                    event: {
                      title: calendarDetails.title,
                      startTime: calendarDetails.startTime,
                      endTime: calendarDetails.endTime,
                      name: calendarDetails.title,
                      location: calendarDetails.location
                    },
                    buttonLabel: t('add-to-calendar')
                  }}
                  stretch={true}
                  buttonSize="md"
                />
              )}
            </>
          )}
        </div>
        <p className="mt-20 text-label-xs text-npl-text-icon-on-light-surface-tertiary">
          {t('need-to-cancel')}
        </p>
      </div>
    </div>
  );
};

export default UpcomingBookingCard;

import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import Player from './LottiePlayer';
import { useDocument } from './ToastContainer/useDocument';

export function showConfetti() {
  if (!document) return;
  const showConfettiEvent = new CustomEvent('show-confetti');
  document.dispatchEvent(showConfettiEvent);
}

const ConfettiContainer = () => {
  const [show, setShow] = useState(false);
  const _document = useDocument();

  useEffect(() => {
    function showConfetti() {
      setShow(true);

      setTimeout(() => {
        setShow(false);
      }, 2000);
    }

    document.addEventListener('show-confetti', showConfetti);

    return () => {
      document.removeEventListener('show-confetti', showConfetti);
    };
  }, []);

  if (typeof window === 'undefined') return null;

  return createPortal(
    show ? (
      <div className="c-ConfettiContainer fixed inset-0 left-1/2 flex -translate-x-1/2 flex-col justify-end">
        <Player autoplay src="/animation/confetti.json" />
      </div>
    ) : null,
    document.body
  );
};

export default ConfettiContainer;
